import './business-topic-link.css'

import PropTypes from 'prop-types'
import React from 'react'

class BusinessTopicLink extends React.Component {
  state = {
    hovering: false,
  }

  _hoverIN = () => {
    this.setState({
      hovering: true,
    })
  }

  _hoverOUT = () => {
    this.setState({
      hovering: false,
    })
  }

  render() {
    let {
      colsize,
      backgroundImage,
      title,
      subtitle,
      subtitle2,
      id,
    } = this.props

    const { hovering } = this.state

    colsize = colsize || 'two'
    backgroundImage = backgroundImage || './img/pic01.jpg'
    title = title || ''
    subtitle = (hovering ? subtitle2 || subtitle : subtitle) || ''

    return (
      <div
        className={`business-vertical ${id} column is-${colsize}-fifths is-paddingless is-flex`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div
          className="is-flex section businesss-vertical-container"
          onMouseEnter={this._hoverIN}
          onMouseOut={this._hoverOUT}
        >
          <p className="title has-text-white is-2">
            <span>{`${title}`}</span>
          </p>
          <p className="subtitle has-text-white is-5 ">{`${subtitle}`}</p>
        </div>
      </div>
    )
  }
}

BusinessTopicLink.propTypes = {
  colsize: PropTypes.string,
  backgroundImage: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string,
  id: PropTypes.string,
}

export default BusinessTopicLink
