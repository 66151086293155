import Banner from '../components/Banner';
import BusinessTopicLink from '../components/BusinessTopicLink';
import Contact from '../components/Contact';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import React from 'react';

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Simiancraft"
          has-text-white="Simiancraft"
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' }
          ]}
        />

        <Banner />

        <section className="business-verticals section is-paddingless has-background-black">
          <div className="columns is-paddingless is-marginless is-vcentered">
            <BusinessTopicLink
              id={`mobile-hybrid-apps`}
              colsize="two"
              backgroundImage="./img/pic01.jpg"
              title="Mobile Hybrid Apps"
              subtitle="Cutting Edge React Native Apps"
              subtitle2="We are the React native Experts"
            />
            <BusinessTopicLink
              id={`business-software`}
              colsize="three"
              backgroundImage={`./img/pic02.jpg`}
              title="Innovative Business Software"
              subtitle="Transform Your Business with Modern Software"
            />
          </div>

          <div className="columns is-paddingless is-marginless">
            <BusinessTopicLink
              id={`data-viz`}
              colsize="three"
              backgroundImage="./img/pic03.jpg"
              title="Data Visualization"
              subtitle="Interactive Charts, Graphs, Maps, Dashboards"
            />
            <BusinessTopicLink
              id={`enterprise-software`}
              colsize="two"
              backgroundImage={`./img/pic04.jpg`}
              title="Enterprise Software"
              subtitle="Dashboards, Vizualizations, Integrations"
            />
          </div>

          <div className="columns is-paddingless is-marginless">
            <BusinessTopicLink
              id={`vr`}
              colsize="two"
              backgroundImage="./img/pic05.jpg"
              title="Virtual Reality"
              subtitle="Immerive Software: The Future of Tech + Industry"
            />
            <BusinessTopicLink
              id={`interactive-art`}
              colsize="three"
              backgroundImage={`./img/pic06.jpg`}
              title="Interactive Art"
              subtitle="The Intersection of Art, Software, Sensors, Light and Sound"
            />
          </div>
        </section>
        <Contact />
      </Layout>
    );
  }
}

export default HomeIndex;
