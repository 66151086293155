import './index.scss';

import { Link } from 'gatsby';
import React from 'react';
import ShadertoyReact from 'shadertoy-react';
import frag from './interactive-nebula.frag';

const companyTitle = 'simiancraft'
  .split('')
  .map((c, i) => <span key={`cotitle-${i}`}>{`${c}`}</span>);

const Banner = props => (
  <section className="banner hero is-large section is-black">
    <div className="hero-billboard has-branded-billboard">
      <ShadertoyReact fs={frag} />
    </div>
    <div className="hero-body">
      <div className="container">
        <h1 className="title has-text-white is-branded is-1 is-size-3-mobile ">
          {companyTitle}
        </h1>
        <h2 className="subtitle has-text-white is-3 is-size-7-mobile is-size-4-tablet">
          <span>mobile apps</span>
          <span>·</span>
          <span>dashboards</span>
          <span>·</span>
          <span>vr</span>
          <span>·</span>
          <span>whizbang in your browser</span>
        </h2>
        <Link className="button contact is-outlined is-white" to="#contact">
          Schedule a Call
        </Link>
      </div>
    </div>
  </section>
);

export default Banner;
