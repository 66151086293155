import React from 'react'

class Contact extends React.Component {
  state = {
    formData: {},
  }

  updateForm = fieldName => event => {
    if (fieldName) {
      let _newFormData = {
        ...this.state.formData,
        ...{ [fieldName]: event.target.value },
      }
      this.setState({ formData: _newFormData })
    }
  }

  injectFormControls = formName => {
    return {
      name: formName,
      id: formName,
      value: this.state[formName],
      onChange: this.updateForm(formName),
    }
  }

  formIsValid = () => {
    const { name, email, subject, message } = this.state.formData
    return name && email && subject && message
  }

  render() {
    return (
      <div className="container">
        <section id="contact" className="contact section">
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/thanks/"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Your Info</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      placeholder="Name"
                      {...this.injectFormControls('name')}
                    ></input>
                    <span className="icon is-small is-left">
                      <i className="fa fa-user" />
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="text"
                      placeholder="Company"
                      {...this.injectFormControls('company')}
                    ></input>
                    <span className="icon is-small is-left">
                      <i className="fa fa-building" />
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      className="input"
                      type="email"
                      placeholder="Email"
                      {...this.injectFormControls('email')}
                    ></input>
                    <span className="icon is-small is-left">
                      <i className="fa fa-envelope"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">The Project</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      className="input"
                      type="text"
                      placeholder="Startup needs 2 MVP mobile apps"
                      {...this.injectFormControls('subject')}
                    ></input>
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">The Details</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <textarea
                      className="textarea"
                      placeholder="Are you available on {Friday at 10am PST} to discuss project details?"
                      {...this.injectFormControls('message')}
                    ></textarea>
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label"> </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <button
                      className={`button is-outlined ${this.formIsValid() &&
                        'is-success'}`}
                      type="submit"
                      value="Send Message"
                      disabled={!this.formIsValid()}
                    >
                      {`Send Message`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    )
  }
}

export default Contact
